$primary-color: #000000;

// @import 'animate.css';
// @import '~loaders.css/src/animations/pacman.scss';


@font-face {
  font-family: 'Libre Bodoni';
  src: url('./assets/fonts/LibreBodoni-Regular.ttf') format('woff2'),
    url('./assets/fonts/LibreBodoni-Regular.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bodoni';
  src: url('./assets/fonts/Bodoni.woff2') format('woff2'),
    url('./assets/fonts/Bodoni.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lekton';
  src: url('./assets/fonts/Lekton.woff2') format('woff2'),
    url('./assets/fonts/Lekton.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Text';
  src: url('./assets/fonts/Crimson Text Regular.woff2') format('woff2'),
    url('./assets/fonts/Crimson Text Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


// https://www.onlinewebfonts.com/search?q=Isabella+Sans

input,
textarea {
  font-family: 'Lekton';
}

// .loader-hidden {
//   display: none;
// }

// .loader-active {
//   display: block;
//   position: absolute;
//   left: 0;
//   right: 0;
//   margin: auto;
//   top: 0;
//   bottom: 0;
//   width: 50px;
//   height: 50px;
//   animation: fadeOut 1s 1s;
//   animation-fill-mode: forwards;
// }
