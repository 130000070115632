.home-page {

    .content {
      width: 100%;
      max-width: 1000px;
      height: auto;
    }

    .text-zone {
      // position: absolute;
      // text-align: center;
      // width: 80%;
      // top: 5%;
      width: 100%;
      max-width: 826px;
      height: auto;
      text-align: center;

      h1 {
        text-align: center;
        width: 100%;
        font-size: 46px;
        font-family: 'Libre Bodoni';
        font-weight: semibold;
        margin-bottom: 7rem;
        color: #444345;
        -webkit-text-stroke: 1.2px #000000;
        text-stroke: 1.2px #000000;
        paint-order: stroke fill;
        line-height: 5rem;
        letter-spacing: 15px;
        margin-top: 6%;        
      }

      h2 {
        text-align: center;
        width: 100%;
        font-family: 'Lekton', sans-serif;
        font-size: 22px;
        margin-bottom: 6rem;
        color: #444345;
        letter-spacing: 10px;
      }

      h3, h4 {
        text-align: center;
        width: 100%;
        font-family: 'Lekton', sans-serif;
        font-size: 16px;
        margin-bottom: 3rem;
        color: #444345;
        letter-spacing: 0.5px;
      }

      h4 {
        margin-bottom: 2rem;
      }

      .resume-button {
        display: inline-block;
        margin-top: 2rem;
        margin-bottom: 30rem;
        padding: 6px 30px;
        background-color: rgba(192, 170, 129, 0.5);
        border: 1px solid #C0AA81;
        border-radius: 10px;
        color: #444345;
        font-weight: bold;
        text-decoration: none;
        font-family: 'Libre Bodoni', serif;
        font-size: 18px;
        transition: all 0.3s ease-in-out;
        max-width: fit-content;

        &:hover {
          background-color: #C0AA81;
        }
      }

    }


    @keyframes fadeIn {
        from{opacity: 0;}
        to {opacity: 1;}
    }   

}


// @media screen and (max-width: 1200px) {
//   .tags,
//   .home-page h1:before,
//   .home-page h1:after {
//     display: none;
//   }

//   .home-page .text-zone {
//     position: initial;
//     width: 100%;
//     transform: none;
//     padding: 20px;
//     box-sizing: border-box;

//     h1 {
//       margin-top: 13%
//     }

//     h2 {
//       margin-top: 20%;
//       margin-bottom: 20%;
//     }
//   }

// }

@media (max-width: 768px) {
  .home-page {
      .text-zone {
          padding: 0 15px;
          box-sizing: border-box;
          
          h1 {
              font-size: 32px;  // Reduced font size
              letter-spacing: 8px;  // Reduced letter spacing
              line-height: 3.5rem;  // Adjusted line height
              margin-bottom: 4rem;  // Adjusted margin
              -webkit-text-stroke: 0.8px #000000;  // Reduced stroke width
          }

          h2 {
              font-size: 18px;  // Reduced font size
              letter-spacing: 5px;  // Reduced letter spacing
              margin-bottom: 3rem;
          }

          h3, h4 {
              font-size: 14px;
              margin-bottom: 2rem;
          }
      }

      .resume-button {
          margin-bottom: 15rem;  // Reduced margin for mobile
      }
  }
}