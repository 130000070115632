/* Custom Navbar Styles */

.custom-navbar {
  background-color: transparent !important;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  .container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
  }

  .navbar-collapse {
    flex-grow: 0;
    justify-content: center;
    background-color: transparent;
    
    &.show {
      background-color: transparent;
    }
  }

  .nav-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    .nav-link {
      font-family: 'Crimson Text';
      font-size: 13px;
      color: #444345;
      transition: color 0.3s ease;
      margin: 0 3rem;
      text-align: center;
      
      &:hover {
        color: #9e9c9c;
      }
    }
  }

  .navbar-toggler {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0 auto;
    
    &:focus {
      box-shadow: none;
    }
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2868, 67, 69, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
}

/* Mobile styles */
@media (max-width: 990px) {
  .custom-navbar {
    background-color: #E5E6E1;

    .nav-content {
      background-color: #E5E6E1;
      flex-direction: column;
      align-items: center;
      
      .nav-link {
        margin: 0.5rem 0;
      }
    }
  }
}