/* html {
  font-size: 62.5%;
} */

body, html {

  width: 100vw;
  height: 100%;  
  margin: 0;
  padding: 0;
  font: 300 11px/1.4 'Isabella Sans', sans-serif;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #f1f0ee; */
  overflow-y: scroll;
  overflow-x: hidden;
  display: block;
  /* padding-bottom: 800px; */
}

/* .dashboard {
  margin-top: 200px;
  margin-left: 200px;
} */

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}