.container.about-page {
    

    img {
      float: right;
      margin-left: 70px;
      margin-top: -70px;
      width: 25%;
      height: auto;
      animation: none;
    }

      h1 {
        font-size: 30px;
        font-family: 'Libre Bodoni';
        color: #444345;
        font-weight: 400;
        margin-top: 0;
        // margin-left: 30%;
        position: relative;
        margin-bottom: 35px;
        left: -10spx;
        // animation: fadeIn 1s 0.8s backwards;
      }
  
      p {
        font-size: 14px;
        color: #444345;
        font-family: "Crimson Text";
        font-weight: 300;
        max-width: fit-content;
      }
  
    .text-animate-hover {
      &:hover {
        color: #878787;
      }
    }
  }
