.footer {
  width: 100%;
  background: #444345;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  will-change: transform;

  &.visible {
    transform: translateY(0);
  }

  &.hidden {
    transform: translateY(100%);
  }

  p {
    margin: 0;
    color: #fff;
    font-family: 'Crimson Text', cursive;
    font-size: 14px;
  }
}
