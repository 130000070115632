.publications-page {
   
    
  h1 {
    text-align: left;
    font-size: 30px;
    font-family: 'Libre Bodoni';
    color: #000000;
    font-weight: 400;
    margin-top: 0;
    // margin-left: 30%;
    position: relative;
    margin-bottom: 35px;
    left: -10spx;
    // animation: fadeIn 1s 0.8s backwards;

  }

    ul {
      padding: 0;
      list-style: none;
      text-align: left;
  
      li {

        font-size: 14px;
        color: #000000;
        font-family: "Crimson Text";
        margin-bottom: 20px;
        font-weight: 300;
        max-width: fit-content;
        // animation: fadeIn 1s 1.2s backwards;
  
      }
    } 

}