.App {
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100vw;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.page {
  flex: 1;
  width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 45px;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../assets/images/DallE_bw_network_high_res-cropped2.png') no-repeat center center fixed;
    background-size: cover;
    z-index: 0;
  }
}

.page-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 2rem 0;
  position: relative;
  z-index: 1;
}

.top-tags {
  bottom: auto;
  top: 35px;
}

.tags {
  color: #c30202;
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 240px;
  font-size: 18px;
  font-family: 'Isabella Sans', cursive;
}

.bottom-tag-html {
  margin-left: -20px;
}

.container.home-page, .container.about-page, .container.publications-page, .container.resume-page {
  width: 100%;
  position: absolute;
  will-change: contents;
  height: 80%;
  min-height: 500px;
  opacity: 1;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  transform-style: preserve-3d;
}

.text-zone {
  position: absolute;
  float: center;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  vertical-align: middle;
  display: table-cell;
}  


// .hamburger-icon {
//   display: none;
//   font-size: 24px;     // Added
//   color: #181818;        // Added  
// }

// .close-icon {
//   display: none;
//   font-size: 24px;     // Added
//   color: #181818;        // Added  
// }

// @media screen and (max-width: 768px) {

//   .hamburger-icon {
//     position: fixed;
//     display: block;
//     top: 20px;
//     right: 20px;
//     z-index: 2;

//     &.mobile-show {
//       display: none;
//     }
//   }

//   .close-icon {
//     display: none;
//     position: fixed;
//     top: 20px;
//     right: 20px;
//     cursor: pointer;

//     &.mobile-show {
//       display: block;
//       z-index: 1000;
//     }
//   }

//   .sidebar-div {
//     display: none;
        
//     &.mobile-show {
//       display: block;
//     }
//   }

//   .container.about-page, .container.publications-page, .container.resume-page {

//     .text-zone {

//       height: auto;
//       position: absolute;
//       float: center;
//       top: 10%;
//       left: 50%;
//       transform: translateX(-50%);
//       width: 80%;
//       vertical-align: middle;
//       display: table-cell;

//       img {
//         width: 50%;
//         margin: 0 0 0 10px;
//         display: inline;
//       }

//       p {
//         display: inline;
//       }

//       h1 {
//         font-size: 40px;
//         margin-bottom: 20px;

//         &:before,
//         &:after {
//           font-size: 14px;
//         }
//       }

//       ul {
//         li {
//           font-size: 13px;
//         }
//       }
//     }
//   }

//   body.mobile-menu-open {
//     position: fixed;
//     width: 100%;
//     overflow: hidden;
//   }

// }